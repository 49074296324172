import {
  hamburgerButton,
  mainMenu,
  modalContainer,
  menuButtonClose,
  emailLink,
} from "./ui";

const firstMenuItem = emailLink;
const lastMenuItem = menuButtonClose;

let menuIsOpen = false;
let previouslyFocusedElement = null;

const openMenu = () => {
  previouslyFocusedElement = document.activeElement;
  menuIsOpen = true;
  modalContainer.classList.remove("display-none");
  window.setTimeout(() => {
    mainMenu.classList.add("open");
    modalContainer.classList.add("open");
    modalContainer.setAttribute("aria-hidden", "false");
    menuButtonClose.focus();
  }, 50);
};
const closeMenu = () => {
  menuIsOpen = false;
  mainMenu.classList.remove("open");
  modalContainer.classList.remove("open");
  modalContainer.setAttribute("aria-hidden", "true");
  previouslyFocusedElement?.focus();
  previouslyFocusedElement = null;
};

export const toggleMenu = () => {
  menuIsOpen ? closeMenu() : openMenu();
};

const initializeMenus = () => {
  hamburgerButton.addEventListener("click", () =>
    menuIsOpen ? closeMenu() : openMenu()
  );

  modalContainer.addEventListener("click", (event) => {
    if ((event.target as HTMLElement).id === "modal-container") {
      closeMenu();
    }
  });

  menuButtonClose.addEventListener("click", closeMenu);

  modalContainer.addEventListener("transitionend", (event) => {
    if (!menuIsOpen) {
      modalContainer.classList.add("display-none");
    }
  });

  window.addEventListener("keydown", function (event) {
    if (menuIsOpen && event.key === "Tab") {
      if (document.activeElement === firstMenuItem && event.shiftKey) {
        event.preventDefault();
        lastMenuItem.focus();
      } else if (document.activeElement === lastMenuItem && !event.shiftKey) {
        event.preventDefault();
        firstMenuItem.focus();
      }
    }
  });
};

export default initializeMenus;
