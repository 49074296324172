import BeatIndicator from "./BeatIndicator";
import { btnTap, canvas, mainContainer, tempoDisplay } from "./ui";

// @ts-ignore
const imgTick32 = require("./assets/favicon-tick-32.png");
// @ts-ignore
const imgTock32 = require("./assets/favicon-tock-32.png");

// Reverting this to 1 for all devices because it was too
// slow on hi-dpi screens
export const pixelRatio = 1; // window.devicePixelRatio || 1

const Visualizer = function (metronome) {
  this.clickCounter = 0;
  this.time = 0;
  this.previousFrameTime = Date.now();
  this.beatIndicators = [];
  this.beatIndicatorPosition = {
    x: 0,
    y: 0,
    cx: 0,
    cy: 0,
    width: 0,
    height: 0,
  };
  this.tempoDisplayPosition = {
    x: 0,
    y: 0,
    cx: 0,
    cy: 0,
    width: 0,
    height: 0,
  };

  this.faviconTick = null;
  this.faviconTock = null;

  const context = canvas.getContext("2d");
  this.context = context;
  this.canvas = canvas;
  this.metronome = metronome;

  this.phase = 0;

  this.prevRect = mainContainer.getBoundingClientRect();
  this.onLayout();
  window.addEventListener("resize", this.onLayout.bind(this));
  window.addEventListener("orientationchange", this.onLayout.bind(this));
  window.addEventListener("scroll", this.onLayout.bind(this));

  window.requestAnimationFrame(this.update.bind(this));
};

Visualizer.prototype.onLayout = function () {
  const mainRect = mainContainer.getBoundingClientRect();
  let tapRect = btnTap.getBoundingClientRect();
  tapRect.x += window.scrollX;
  tapRect.y += window.scrollY;
  let tempoRect = tempoDisplay.getBoundingClientRect();
  tempoRect.x += window.scrollX;
  tempoRect.y += window.scrollY;

  this.canvas.width = mainRect.width * pixelRatio;
  this.canvas.height = mainRect.height * pixelRatio;

  this.beatIndicatorPosition.x = tapRect.x * pixelRatio;
  this.beatIndicatorPosition.y = tapRect.y * pixelRatio;
  this.beatIndicatorPosition.cx = (tapRect.x + tapRect.width / 2) * pixelRatio;
  this.beatIndicatorPosition.cy = (tapRect.y + tapRect.height / 2) * pixelRatio;
  this.beatIndicatorPosition.width = tapRect.width * pixelRatio;
  this.beatIndicatorPosition.height = tapRect.height * pixelRatio;

  this.tempoDisplayPosition.x = tempoRect.x * pixelRatio;
  this.tempoDisplayPosition.y = tempoRect.y * pixelRatio;
  this.tempoDisplayPosition.cx =
    (tempoRect.x + tempoRect.width / 2) * pixelRatio;
  this.tempoDisplayPosition.cy =
    (tempoRect.y + tempoRect.height / 2) * pixelRatio;
  this.tempoDisplayPosition.width = tempoRect.width * pixelRatio;
  this.tempoDisplayPosition.height = tempoRect.height * pixelRatio;
};

// TODO: Handle fails
Visualizer.prototype.loadImage = function (src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.src = src;
  });
};

Visualizer.prototype.preload = async function () {
  this.faviconTick = await this.loadImage(imgTick32);
  this.faviconTock = await this.loadImage(imgTock32);
  return Promise.all([this.faviconTick, this.faviconTock]);
};

Visualizer.prototype.update = function () {
  // Delta time
  const now = Date.now();
  const deltaTime = now - this.previousFrameTime;
  this.previousFrameTime = now;

  // Phase
  const currentPhase = this.metronome.getPhase();
  this.phase = currentPhase
    ? currentPhase
    : Math.abs(this.phase) < 0.01
    ? 0
    : this.phase * 0.8;

  this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

  // Update, remove, and draw all beat indicators.
  // Render newest ones on top.
  this.beatIndicators = this.beatIndicators.filter((beat) => !beat.expired());
  this.context.fillStyle = "yellow";
  const x = this.beatIndicatorPosition.x + this.beatIndicatorPosition.width / 2;
  const y =
    this.beatIndicatorPosition.y + this.beatIndicatorPosition.height / 2;

  for (let beat of this.beatIndicators) {
    beat.update(deltaTime);
    beat.draw(
      this.context,
      x,
      y,
      this.beatIndicatorPosition.width,
      this.beatIndicatorPosition.height
    );
  }

  //
  // Draw pendulum
  //
  const rotation = this.phase * 0.8;

  const w = 2 * pixelRatio;
  const h = 220 * pixelRatio;
  const { cx } = this.tempoDisplayPosition;
  const cy = this.tempoDisplayPosition.y + this.tempoDisplayPosition.height;

  this.context.fillStyle = "#fff";
  this.context.translate(cx, cy);
  this.context.rotate(rotation);
  this.context.translate(-cx, -cy);

  // Pendulum
  const boltRadius = h * 0.05;
  this.context.fillRect(cx - w / 2, cy - h * 0.8, w, h * 0.8 - boltRadius);
  this.context.fillRect(cx - w / 2, cy + boltRadius, w, h * 0.1);

  // Bolt
  this.context.beginPath();
  this.context.lineWidth = w;
  this.context.strokeStyle = "#ffffff";
  this.context.ellipse(
    cx,
    cy,
    12 * pixelRatio,
    12 * pixelRatio,
    0,
    0,
    2 * Math.PI
  );
  this.context.stroke();
  this.context.closePath();

  this.context.setTransform(1, 0, 0, 1, 0, 0);

  window.requestAnimationFrame(this.update.bind(this));
};

Visualizer.prototype.onClick = function (tickTock) {
  this.beatIndicators.push(new BeatIndicator());

  if (this.faviconTick && this.faviconTock) {
    let icon = document.querySelector("#fav");
    let newIcon = icon.cloneNode(true) as HTMLElement;
    newIcon.setAttribute(
      "href",
      tickTock
        ? this.faviconTick.getAttribute("src")
        : this.faviconTock.getAttribute("src")
    );
    icon.parentNode?.replaceChild(newIcon, icon);
  }

  this.clickCounter++;
};

export default Visualizer;
