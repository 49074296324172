import NoSleep from 'nosleep.js';
const noSleep = new NoSleep();

const Metronome = function (audioContext, onClickSchedule, onClick) {
  this.MAX_TEMPO = 240;
  this.MIN_TEMPO = 30;

  this.tempo = 90;
  this.nextClickTime = null;
  this.nextClickScheduled = false;
  this.tickTock = true;
  this.onClickSchedule = onClickSchedule;
  this.onClick = onClick;
  this.lookahead = 100 / 1000;
  this.audioContext = audioContext;

  this.updateLoop();
};

Metronome.prototype.getBeatDuration = function () {
  return 60 / this.tempo;
};

Metronome.prototype.updateLoop = function () {
  const { currentTime } = this.audioContext;
  const { nextClickTime, lookahead } = this;

  if (nextClickTime && !this.nextClickScheduled && currentTime > nextClickTime - lookahead) {
    this.onClickSchedule(nextClickTime);
    this.nextClickScheduled = true;
  }

  if (nextClickTime && currentTime > nextClickTime) {
    this.tickTock = !this.tickTock;
    this.nextClickTime = nextClickTime + this.getBeatDuration();
    this.nextClickScheduled = false;
    this.onClick(this.tickTock);
  }

  window.setTimeout(this.updateLoop.bind(this), 80);
};

Metronome.prototype.getPhase = function () {
  if (this.nextClickTime === null) {
    return null;
  }
  const beatDuration = this.getBeatDuration();
  const timeToNextClick = this.nextClickTime - this.audioContext.currentTime;
  const interpolated = timeToNextClick / beatDuration;
  return Math.sin((this.tickTock ? -interpolated : interpolated) * Math.PI);
};

Metronome.prototype.start = function () {
  this.nextClickTime = this.audioContext.currentTime + this.getBeatDuration();
  noSleep.enable();
};

Metronome.prototype.stop = function () {
  this.nextClickTime = null;
  noSleep.disable();
};

Metronome.prototype.setTempo = function (tempo) {
  const currentTime = this.audioContext.currentTime;
  const nextClickTime = this.nextClickTime;
  const oldDuration = this.getBeatDuration();

  this.tempo = tempo;

  if (nextClickTime === null) {
    return;
  }

  const newDuration = this.getBeatDuration();

  const remainingTime = nextClickTime - currentTime;
  const pct = remainingTime / oldDuration;

  this.nextClickTime = currentTime + pct * newDuration;
};

export default Metronome;
