export const mainContainer: HTMLElement = document.querySelector("main");
export const btnTap: HTMLButtonElement = document.querySelector("#btn-tap");
export const volumeSlider: HTMLInputElement = document.querySelector("#volume");
export const tempoSlider: HTMLInputElement = document.querySelector("#tempo");
export const tempoPot: HTMLInputElement = document.querySelector("#tempo-pot");
export const canvas: HTMLCanvasElement = document.querySelector("canvas");
export const tempoDisplay: HTMLCanvasElement = document.querySelector(
  "#tempo-display"
);
export const tempoNumber: HTMLCanvasElement = document.querySelector(
  "#tempo-num"
);
export const btnStartStop: HTMLButtonElement = document.querySelector(
  "#btn-start-stop"
);
export const loadingContainer: HTMLInputElement = document.querySelector(
  "#loading-container"
);
export const hamburgerButton: HTMLButtonElement = document.querySelector(
  "#hamburger-button"
);
export const modalContainer: HTMLDivElement = document.querySelector(
  "#modal-container"
);
export const mainMenu: HTMLDivElement = document.querySelector("#main-menu");

// Menu items
export const emailLink: HTMLAnchorElement = document.querySelector(
  "#email-link"
);
export const menuButtonClose: HTMLButtonElement = document.querySelector(
  "#menu-btn-close"
);

/**
 * Handle clicks if clicked, or holds if mouse is down for a longer period
 *
 * @param element Button to attach event listeners to
 * @param onClick Function called when element is clicked
 * @param onHold Function called when element is held down. Called once per window.requestAnimationFrame and takes the delta time as a parameter.
 */
export const onClickOrHold = (
  element: HTMLElement,
  onClick: () => void,
  onHold: (dt: number) => void
) => {
  // how long wait until click is interpreted as hold
  const maxClickTime = 500;
  let heldTime = null;
  let lastUpdate = Date.now();

  const update = () => {
    const now = Date.now();
    const dt = now - lastUpdate;
    lastUpdate = now;

    if (heldTime !== null) {
      heldTime += dt;

      if (heldTime > maxClickTime) {
        onHold(dt);
      }
    }

    window.requestAnimationFrame(update);
  };

  // TODO: ontouchstart?
  element.addEventListener("mousedown", (e) => {
    heldTime = 0;
  });

  // TODO: ontouchend?
  element.addEventListener("mouseup", (e) => {
    heldTime = null;
  });

  element.addEventListener("click", (e) => {
    if (heldTime <= maxClickTime) {
      onClick();
    }
  });

  update();
};

/**
 * Potentiometer events
 */
export const potEvents = (
  slider: HTMLElement,
  pot: HTMLElement,
  onDrag: (delta: number) => void
) => {
  let dragging = false;

  let startPos = { x: null, y: null };

  const startDrag = (event) => {
    event.preventDefault();

    startPos = {
      x: event.clientX || event.targetTouches[0].clientX,
      y: event.clientY || event.targetTouches[0].clientY,
    };
    dragging = true;
    pot.classList.add("active");
  };

  const endDrag = () => {
    startPos = { x: null, y: null };
    dragging = false;
    pot.classList.remove("active");
  };

  const dragged = (event) => {
    if (!dragging) return;

    event.preventDefault();

    const currentPos = {
      x: event.clientX || event.targetTouches[0].clientX,
      y: event.clientY || event.targetTouches[0].clientY,
    };

    const delta = {
      x: currentPos.x - startPos.x,
      y: startPos.y - currentPos.y,
    };

    const direction = delta.x + delta.y > 0 ? 1 : -1;
    const change = Math.sqrt(delta.x * delta.x + delta.y * delta.y) * direction;

    startPos = currentPos;

    onDrag(change);
  };

  pot.addEventListener("mousedown", startDrag);
  pot.addEventListener("touchstart", startDrag);

  document.addEventListener("mouseup", endDrag);
  pot.addEventListener("touchend", endDrag);
  document.addEventListener("touchcancel", endDrag);

  document.addEventListener("mousemove", dragged);
  pot.addEventListener("touchmove", dragged, { passive: false });

  // TODO: Mousewheel
  // pot.addEventListener("mousewheel", this.mousewheel.bind(this), {
  //   passive: false,
  // });
};
