import { average, msToBpm } from "./utils";

const maxTaps = 4; // The number of taps to  tempo over
const resetTime = 1600; // Miliseconds until tapping resets

let timestamps = [];
let timer = null;

const tapTempo = () => {
  // Add current timestamp
  timestamps.unshift(Date.now());
  if (timestamps.length > maxTaps) {
    timestamps.pop();
  }

  // Calcluate average BPM
  let bpms = [];
  for (let i = 0; i < timestamps.length - 1; i++) {
    let bpm = msToBpm(timestamps[i] - timestamps[i + 1]);
    bpms.unshift(bpm);
  }

  // Reset timeout on each tap
  if (timer !== null) {
    window.clearTimeout(timer);
  }

  timer = window.setTimeout(() => {
    timestamps = [];
    timer = null;
  }, resetTime);

  if (bpms.length === 0) {
    return null;
  } else {
    return average(bpms);
  }
}

export default tapTempo;