import { pixelRatio } from "./Visualizer";

const lifetime = 3600;

const BeatIndicator = function () {
  this.time = 0;
};

BeatIndicator.prototype.update = function (dt: number) {
  this.time += dt;
};

BeatIndicator.prototype.draw = function (
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number
) {
  if (this.expired()) {
    return;
  }

  const progress = this.time / lifetime;

  context.strokeStyle = `rgba(255, 204, 0, ${Math.pow(1 - progress, 2)})`;
  context.lineWidth = Math.pow(1 - progress, 6) * 10 * pixelRatio + 1;

  const startWidth = w / 2;

  context.beginPath();
  context.ellipse(
    x,
    y,
    startWidth + 350 * progress * pixelRatio,
    startWidth + 350 * progress * pixelRatio,
    0,
    0,
    2 * Math.PI
  );
  context.stroke();
  context.closePath();
};

BeatIndicator.prototype.expired = function () {
  return this.time > lifetime;
};

export default BeatIndicator;
